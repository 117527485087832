const topicsJsonURL = "/assets/data/top_topics.json";
const newsJsonURL = "/news/data/list.json";
const groupNewsJsonURL = "/group_news/data/list.json";
const eventJsonURL = "/event_campaign/data/list.json";
const noticeJsonURL = "/assets/data/top_notice.json";

const topics_lists = {
  data() {
    return {
      topics_list: [],
      is_ja: $("html").attr("lang") === "ja",
    };
  },
  created() {
    this.getLists(topicsJsonURL);
  },
  mounted() {
    window.addEventListener("wovnApiReady", this.getLang);
  },
  methods: {
    getLang() {
      if (window.WOVN && WOVN.io.getWovnUrl() !== undefined) {
        this.is_ja = WOVN.io.getCurrentLang().code === "ja";
      } else {
        this.is_ja = $("html").attr("lang") === "ja";
      }
      this.getLists(topicsJsonURL);
    },
    getLists: async function (url) {
      const data = await axios.get(url).then((response) => response.data);
      let filtered_data = [];
      if (this.is_ja) {
        filtered_data = data;
      } else {
        filtered_data = data.filter((list) => list["en-none"] == false);
      }
      this.setLists(filtered_data);
    },

    setLists: function (data) {
      let topics_list = [];
      $.each(data, function (index, value) {
        topics_list.push({
          title: value.title,
          thumbnail: value.thumbnail,
          alt: value.alt,
          link: value.link,
          enNone: value["en-none"],
        });
      });
      if (topics_list.length) {
        this.topics_list = topics_list;
      }
    },
  },
};
Vue.createApp(topics_lists).mount("#topics");

const news_lists = {
  data() {
    return {
      news: [],
      group_news: [],
      event_campaign: [],
      top_notice: [],
      en_news: [],
      en_notofication: [],
      show_news: false,
      show_group_news: false,
      show_event_campaign: false,
      show_top_notice: false,
      show_en_news: false,
      show_en_notofication: false,
      is_ja: $("html").attr("lang") === "ja",
    };
  },
  created() {
    this.getAllLists();
  },
  mounted() {
    window.addEventListener("wovnApiReady", this.getLang);
  },
  methods: {
    getLang() {
      if (window.WOVN && WOVN.io.getWovnUrl() !== undefined) {
        this.is_ja = WOVN.io.getCurrentLang().code === "ja";
      } else {
        this.is_ja = $("html").attr("lang") === "ja";
      }
      this.getAllLists();
    },
    getAllLists: function () {
      if (this.is_ja) {
        this.getLists(
          newsJsonURL,
          function (list, self) {
            self.news = list;
          },
          function (self) {
            self.show_news = true;
          }
        );
        this.getLists(
          groupNewsJsonURL,
          function (list, self) {
            self.group_news = list;
          },
          function (self) {
            self.show_group_news = true;
          }
        );
        this.getLists(
          eventJsonURL,
          function (list, self) {
            self.event_campaign = list;
          },
          function (self) {
            self.show_event_campaign = true;
          }
        );
        this.getLists(
          noticeJsonURL,
          function (list, self) {
            self.top_notice = list;
          },
          function (self) {
            self.show_top_notice = true;
          }
        );
      } else {
        this.getMultipleLists(
          [newsJsonURL, eventJsonURL, groupNewsJsonURL],
          function (list, self) {
            self.en_news = list;
          },
          function (self) {
            self.show_en_news = true;
          }
        );
        this.getLists(
          noticeJsonURL,
          function (list, self) {
            self.en_notofication = list;
          },
          function (self) {
            self.show_en_notofication = true;
          }
        );
      }
    },
    getLists: async function (url, setData, isShow) {
      const data = await axios.get(url).then((response) => response.data);
      let filtered_data = [];
      if (this.is_ja) {
        filtered_data = data.filter((list) => list.lang === "jp");
      } else {
        filtered_data = data.filter((list) => list.lang === "en");
      }
      this.setLists(filtered_data, setData, isShow);
    },

    getMultipleLists: async function (urlList, setData, isShow) {
      const promises = urlList.map((url) =>
        axios.get(url).then((response) => response.data)
      );
      const data_list = await Promise.all(promises);
      const formatted_data_list = [];
      for (let i = 0; i < data_list.length; i++) {
        formatted_data_list.push(...data_list[i]);
      }
      let filtered_data = [];
      filtered_data = formatted_data_list.filter((list) => list.lang === "en");
      this.setLists(filtered_data, setData, isShow);
    },

    setLists: function (data, setData, isShow) {
      const jp_news = data.sort(
        (a, b) => parseInt(b.published) - parseInt(a.published)
      );
      let news_list = [];
      let count = -1;
      const maxNewsCount = this.is_ja ? 4 : 5;
      let self = this;
      $.each(jp_news, function (index, value) {
        const date = value.published;
        const year = date.substr(0, 4);
        const month = date.substr(4, 2);
        const day = date.substr(6, 2);
        if (count + 1 < maxNewsCount) {
          news_list.push({
            category: value.category,
            date: year + "/" + month + "/" + day,
            is_pdf: value.pdf_icon,
            link: value.link,
            target: value.target,
            title: value.title,
          });
        } else {
          return false;
        }
        count++;
      });
      if (news_list.length) {
        setData(news_list, self);
        isShow(self);
      }
    },
  },
};
Vue.createApp(news_lists).mount("#news");

document.querySelector(".js-news-tab").addEventListener("click", function (e) {
  switch (e.target.id) {
    case "mec":
      document.querySelector("#js-mec-content").classList.add("is-visible");
      document
        .querySelector("#js-group-content")
        .classList.remove("is-visible");
      document
        .querySelector("#js-event-content")
        .classList.remove("is-visible");
      document.querySelector("#js-info-content").classList.remove("is-visible");
      break;

    case "group":
      document.querySelector("#js-mec-content").classList.remove("is-visible");
      document.querySelector("#js-group-content").classList.add("is-visible");
      document
        .querySelector("#js-event-content")
        .classList.remove("is-visible");
      document.querySelector("#js-info-content").classList.remove("is-visible");
      break;

    case "event":
      document.querySelector("#js-mec-content").classList.remove("is-visible");
      document
        .querySelector("#js-group-content")
        .classList.remove("is-visible");
      document.querySelector("#js-event-content").classList.add("is-visible");
      document.querySelector("#js-info-content").classList.remove("is-visible");
      break;

    case "info":
      document.querySelector("#js-mec-content").classList.remove("is-visible");
      document
        .querySelector("#js-group-content")
        .classList.remove("is-visible");
      document
        .querySelector("#js-event-content")
        .classList.remove("is-visible");
      document.querySelector("#js-info-content").classList.add("is-visible");
      break;
    default:
      break;
  }
});
document
  .querySelector(".js-en-news-tab")
  .addEventListener("click", function (e) {
    switch (e.target.id) {
      case "en-news":
        document
          .querySelector("#js-en-news-content")
          .classList.add("is-visible");
        document
          .querySelector("#js-en-notification-content")
          .classList.remove("is-visible");

        break;
      case "en-notification":
        document
          .querySelector("#js-en-news-content")
          .classList.remove("is-visible");
        document
          .querySelector("#js-en-notification-content")
          .classList.add("is-visible");
        break;
      default:
        break;
    }
  });

const mainVisualSwiper = new Swiper(".main-visual_swiper", {
  direction: "horizontal",
  loop: true,
  loopAdditionalSlides: 1,
  speed: 2200,
  effect: "fade",
  preloadImages: false,
  lazy: {
    loadPrevNext: true,
  },
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },

  watchSlidesProgress: true,
});
const businessAreaSwiper = new Swiper(".business-area_swiper", {
  direction: "horizontal",
  preloadImages: false,
  lazy: {
    loadPrevNext: true,
  },
  scrollbar: {
    el: ".business_swiper-scrollbar",
  },
  navigation: {
    nextEl: ".business_swiper-button-next",
    prevEl: ".business_swiper-button-prev",
  },
  speed: 1000,
  watchSlidesProgress: true,
});

var pcSlidesPerView = 3;
var $slide;
var slidable;
window.addEventListener("wovnApiReady", function () {
  if (window.WOVN && WOVN.io.getWovnUrl() !== undefined) {
    setTimeout(toggleSlidable, 50);
  }
});
window.addEventListener("DOMContentLoaded", function () {
  setTimeout(toggleSlidable, 50);
});

function toggleSlidable() {
  $slide = $(".topics_swiper .swiper-slide");
  slidable = $slide.length > pcSlidesPerView;
  if (window.innerWidth >= 1024) {
    pcSlidesPerView = 3;
    slidable = $slide.length > pcSlidesPerView;
  } else if (window.innerWidth >= 601) {
    pcSlidesPerView = 2;
    slidable = $slide.length > pcSlidesPerView;
  }
  if (!slidable) {
    $(".swiper-controller").hide();
  }
}

var topicsSwiper = new Swiper(".topics_swiper", {
  direction: "horizontal",
  loop: true,
  loopAdditionalSlides: 1,
  slidesPerView: 1,
  spaceBetween: 15,
  preloadImages: false,
  lazy: {
    loadPrevNext: true,
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  watchOverflow: true,

  speed: 1000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },

  watchSlidesProgress: true,

  scrollbar: {
    el: ".swiper-scrollbar",
  },

  breakpoints: {
    601: {
      slidesPerView: 2,
      spaceBetween: 19,
    },
    1024: {
      slidesPerView: pcSlidesPerView,
      spaceBetween: 26,
    },
  },
});

topicsSwiper.on("breakpoint", function () {
  $slide = $(".topics_swiper .swiper-slide");
  $(".swiper-controller").show();
  if (window.innerWidth >= 1024) {
    pcSlidesPerView = 3;
    slidable = $slide.length > pcSlidesPerView;
  } else if (window.innerWidth >= 601) {
    pcSlidesPerView = 2;
    slidable = $slide.length > pcSlidesPerView;
  }
  if (!slidable) {
    $(".swiper-controller").hide();
  }
});
